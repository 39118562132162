/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	investors: [],
	investorProfile: {},
	profileUpdate: null,
	totalInvestors: 0,
	card: {},
	searchInvestors: [],
	searchInvestorsTotal: 0,
	totalInvestorAudits: 0,
	investorPortfolios: [],
	investorAudits: [],
	investorWallets: [],
	investorWishlists: [],
	investorBanks: [],
	totalInvestorMutualFunds: 0,
	investorMutualFunds: [],
	investorSavings: {},
	investorSavingsDetails: {}
};

const getters = {
	getInvestors: (state) => state.investors,
	getInvestorProfile: (state) => state.investorProfile,
	getProfileUpdate: (state) => state.profileUpdate,
	getInvestorCard: (state) => state.card,
	getTotalInvestors: (state) => state.totalInvestors,
	getInvestorsSearch: (state) => state.searchInvestors,
	getTotalSearchInvestors: (state) => state.searchInvestorsTotal,
	getTotalInvestorAudits: (state) => state.totalInvestorAudits,
	getInvestorPortfolios: (state) => state.investorPortfolios,
	getInvestorWallets: (state) => state.investorWallets,
	getInvestorWishlists: (state) => state.investorWishlists,
	getInvestorAudits: (state) => state.investorAudits,
	getInvestorBanks: (state) => state.investorBanks,
	getInvestorMutualFunds: (state) => state.investorMutualFunds,
	getTotalInvestorMutualFunds: (state) => state.totalInvestorMutualFunds,
	getInvestorSavings: (state) => state.investorSavings,
	getInvestorSavingsDetail: (state) => state.savingsDetail
};

const mutations = {
	setInvestors: (state, data) => {
		state.investors = data;
	},

	addInvestor: (state, data) => {
		state.investors = [data, ...state.investors];
	},

	setInvestorProfile: (state, data) => {
		state.investorProfile = data;
	},

	updateInvestorProfile: (state, data) => {
		state.investorProfile = { ...state.investorProfile, ...data };
	},

	updateSuccess: (state) => {
		state.profileUpdate = 'success';
	},

	updateError: (state) => {
		state.profileUpdate = 'error';
	},

	resetUpdate: (state) => {
		state.profileUpdate = null;
	},

	setCard: (state, data) => {
		state.card = data;
	},

	setTotal: (state, data) => {
		state.totalInvestors = data;
	},

	setInvestorsSearch: (state, data) => {
		state.searchInvestors = data;
	},

	setSearchInvestorsTotal: (state, data) => {
		state.searchInvestorsTotal = data;
	},

	setTotalInvestorAudits: (state, data) => {
		state.totalInvestorAudits = data;
	},

	setInvestorMutualFunds: (state, data) => {
		state.investorMutualFunds = data;
	},
	setTotalInvestorMutualFunds: (state, data) => {
		state.totalInvestorMutualFunds = data;
	},

	setInvestorPortfolios: (state, data) => {
		state.investorPortfolios = data;
	},

	setInvestorWallets: (state, data) => {
		state.investorWallets = data;
	},

	setInvestorWishlists: (state, data) => {
		state.investorWishlists = data;
	},

	setInvestorAudits: (state, data) => {
		state.investorAudits = data;
	},

	setInvestorBanks: (state, data) => {
		state.investorBanks = data;
	},
	setInvestorSavings: (state, data) => {
		state.investorSavings = data;
	},
	setInvestorSavingsDetail: (state, data) => {
		state.investorSavingsDetail = data;
	}
};

const actions = {
	fetchAllInvestors: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestors', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'fetch-investors',
						...(error.response.data || 'Error Fetching Investors, Please Try Again.')
					},
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	createInvestor: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('register', data)
			.then((response) => {
				if (response.status === 201) {
					commit('addInvestor', response.data);
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'A new Investor has been created'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'register',
						...(error.response.data || 'An Error occur, Please Try Again.')
					},
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchInvestorProfile: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorProfile', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-profile' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	updateInvestor: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`users/${data.id}`, data)
			.then((response) => {
				if (response.status === 200) {
					commit('updateInvestorProfile', response.data);
					commit('updateSuccess');
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'Investor details have been updated!'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'updateInvestor' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			})
			.finally(() => {
				commit('resetUpdate');
			});
		return true;
	},

	investorCard: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}/card`)
			.then((response) => {
				if (response.status === 200) {
					commit('reqSuccess', null, { root: true });
					commit('setCard', response.data.data);
					return true;
				}
				commit('setCard', {});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-card' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	updateAvatar: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`users/${data.id}/avatar`, { file: data.avatar })
			.then((response) => {
				if (response.status === 200) {
					commit('updateInvestorProfile', { avatar: data.avatar });
					success({
						title: 'Success',
						text: 'Profile Picture has been updated'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-avatar' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	searchInvestors: ({ commit }, data) => {
		const { limit, offset, query } = data;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/search?query=${query}&type=user&limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorsSearch', response.data.data);
					commit('setSearchInvestorsTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'search-investors', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchInvestorPortfolios: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}/portfolio`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorPortfolios', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-portfolio' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchInvestorWallets: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}/wallet`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorWallets', response.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-wallet' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchInvestorWishlists: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}/wishlist`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorWishlists', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-wishlist' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchInvestorAudits: ({ commit }, data) => {
		const [limit, offset, id] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}/audits?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorAudits', response.data.data);
					commit('setTotalInvestorAudits', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-audit' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchInvestorMutualFunds: ({ commit }, data) => {
		const [limit, offset, id] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}/mutualfunds?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorMutualFunds', response.data.data);
					commit('setTotalInvestorMutualFunds', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-mutualFunds' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchInvestorBanks: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}/bank`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorBanks', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-bank' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	updateInvestorKyc: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`users/${data.id}/kyc`, data)
			.then((response) => {
				if (response.status === 200) {
					commit('updateInvestorProfile', response.data);
					commit('updateSuccess');
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'Investor kyc have been updated!'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'updateInvestorKyc' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			})
			.finally(() => {
				commit('resetUpdate');
			});
		return true;
	},
	fetchSavings: ({ commit }, data) => {
		const [id, limit, offset] = data;
		let url = `users/${id}/savings`;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		if (limit && offset) {
			url += `?limit=${limit}&offset=${offset}`;
		}
		api.get(url)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorSavings', response.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'savings', ...(error.response.data || 'Error Fetching savings, Please Try Again.') },
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},
	fetchInvestorSavingsDetail: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`savings/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorSavingsDetail', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'savings-detail' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
