/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	withdrawalRequests: [],
	newWithdrawalRequests: [],
	totalWithdrawalRequests: 0,
	totalNewWithdrawalRequests: 0,
	withdrawalRequest: {}
};

const getters = {
	getWithdrawalRequests: (state) => state.withdrawalRequests,
	getNewWithdrawalRequests: (state) => state.newWithdrawalRequests,
	getTotalWithdrawalRequests: (state) => state.totalWithdrawalRequests,
	getTotalNewWithdrawalRequests: (state) => state.totalNewWithdrawalRequests,
	getWithdrawalRequest: (state) => state.withdrawalRequest
};

const mutations = {
	setWithdrawalRequests: (state, data) => {
		state.withdrawalRequests = data;
	},

	setNewWithdrawalRequests: (state, data) => {
		state.newWithdrawalRequests = data;
	},

	updateSuccess: (state) => {
		state.updateStatus = 'success';
	},

	updateError: (state) => {
		state.updateStatus = 'error';
	},

	resetUpdate: (state) => {
		state.updateStatus = null;
	},

	setTotal: (state, data) => {
		state.totalWithdrawalRequests = data;
	},

	setNewTotal: (state, data) => {
		state.totalNewWithdrawalRequests = data;
	},

	updateWithdrawalRequest: (state, data) => {
		state.withdrawalRequest = { ...state.withdrawalRequest, ...data };
	}
};

const actions = {
	fetchAllWithdrawalRequests: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`wallets/request?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setWithdrawalRequests', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'fetch-withdrawalRequests',
						...(error.response.data || 'Error Fetching banks, Please Try Again.')
					},
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	approveWithdrawalRequest: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`wallets/request/${data.id}`, {
			status: data.status,
			admin_id: data.admin_id
		})
			.then((response) => {
				if (response.status === 200) {
					commit('updateWithdrawalRequest', response.data);
					commit('updateSuccess');
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'Withdrawal Request has been updated!'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'updateWithdrawalRequest' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			})
			.finally(() => {
				commit('resetUpdate');
			});
		return true;
	},

	fetchNewWithdrawalRequest: ({ commit }) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get('wallets/request?status=NEW')
			.then((response) => {
				if (response.status === 200) {
					commit('setNewWithdrawalRequests', response.data.data);
					commit('setNewTotal', response.data.total);
					commit('updateSuccess');
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'newWithdrawalRequest' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			})
			.finally(() => {
				commit('resetUpdate');
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
