/* eslint-disable import/no-cycle */
import store from '../store/index';
import eventHub from './eventHub';
import axios from './axios';

/**
 * Saves the logged user's details
 * @param {Object} userData
 */
export function setSession(user) {
	store.commit('setLoggedUser', user);
}

/**
 * Resets the logged-in user
 */
export function clearSession() {
	setSession({});
}

/**
 * Retrieves the logged in user
 * @return {Object} user - Stored user
 */
export function getSessionUser() {
	return store.getters.getLoggedUser;
}

/**
 * Checks if a user is logged in
 * @returns {Boolean} returns true or false
 */
export function isLoggedIn() {
	return store.state.auth.isLoggedIn;
}

/**
 * Auth guard allows authenticated users only.
 * @param to - next route
 * @param from - previous route
 * @param next - callback to transfer control to the next middleware
 */
export function requireAuth(to, from, next) {
	if (isLoggedIn()) {
		next();
	} else {
		next({ name: 'login' });
	}
}

/**
 * Auth guard that allows non-authenticated users only.
 * @param to - the next route
 * @param from - the previous route
 * @param next - callback to transfer control to the next middleware
 */
export function noAuthOnly(to, from, next) {
	if (isLoggedIn()) {
		next({ path: '/' });
	} else {
		next();
	}
}

/**
 * Calls an action to refresh user token
 */
export function refreshToken() {
	store.dispatch('doTokenRefresh');
}

/**
 * restarts the session with
 * @param {Object} data - User data to be initialized in session
 */
export function restartSession(data) {
	eventHub.$session.start();
	eventHub.$session.set('user', data);
	eventHub.$session.set('token', store.getters.getLoggedUser.token);
	eventHub.$session.set('refreshToken', eventHub.loggedUser.refreshToken);
	setSession(data);
	axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
}
