/* eslint-disable import/extensions */
import Vue from 'vue';
import VueSession from 'vue-session';
import jwtDecode from 'jwt-decode';
import swal from 'sweetalert2';
import VueProgressBar from 'vue-progressbar';
import moment from 'moment';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import axios from './utils/axios';

import App from './App.vue';
import router from './router';
import store from './store/index';
import './registerServiceWorker';
import { setSession, clearSession, refreshToken } from './utils/auth';

const options = {
	persist: true
};

const progressbarOptions = {
	thickness: '4px',
	transition: {
		speed: '0.2s',
		opacity: '0.6s',
		termination: 300
	},
	autoRevert: true,
	location: 'top',
	inverse: false,
	color: '#3D6DFF'
};

const bugsnagClient = bugsnag({
	apiKey: process.env.VUE_APP_BUGSNAG_KEY,
	notifyReleaseStages: ['production', 'staging'],
	releaseStage: process.env.VUE_APP_NODE_ENV
});

bugsnagClient.use(bugsnagVue, Vue);

window.bugsnagClient = bugsnagClient;

Vue.filter('formatCurrency', (val, currency = 'NGN') => {
	const country = currency === 'NGN' ? 'NG' : 'US';

	const formatter = new Intl.NumberFormat(`en-${country}`, {
		style: 'currency',
		currency,
		minimumFractionDigits: 2
	});

	return formatter.format(val);
});

Vue.use(VueProgressBar, progressbarOptions);
Vue.use(VueSession, options);
Vue.use(CKEditor);

Vue.filter('formatCurrency', (val, currency = 'NGN') => {
	const country = currency === 'NGN' ? 'NG' : 'US';

	const formatter = new Intl.NumberFormat(`en-${country}`, {
		style: 'currency',
		currency,
		minimumFractionDigits: 2
	});

	return formatter.format(val);
});

window.moment = moment;
window.swal = swal;

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
	data() {
		return {
			userId: null
		};
	},
	created() {
		if (this.isLoggedIn) {
			try {
				const token = jwtDecode(this.loggedUser.token);
				const time = Date.now().valueOf() / 1000;
				if (token.exp < time) {
					this.$session.clear();
					refreshToken();
				} else this.resumeSession();
			} catch (error) {
				this.endSession();
				return error;
			}
			return true;
		}
		return true;
	},
	computed: {
		isLoggedIn() {
			return store.getters.isLoggedIn;
		},
		loggedUser() {
			return store.getters.getLoggedUser;
		},
		isRefreshingToken() {
			return store.getters.refreshingToken;
		}
	},
	watch: {
		isLoggedIn(val) {
			if (val) {
				this.handleSession(this.loggedUser);
				this.userId = this.loggedUser.id;
			}
		},
		isRefreshingToken(status) {
			if (status) {
				this.handleSession(this.loggedUser);
			}
		}
	},
	methods: {
		/**
		 * Starts a session with logged user data
		 * @param {Object} data
		 */
		handleSession(data) {
			this.$session.start();
			this.$session.set('user', data);
			this.$session.set('token', this.loggedUser.token);
			setSession(data);
			axios.defaults.headers.common.Authorization = `Bearer ${this.loggedUser.token}`;
			store.commit('setLoggedUser', data);
		},
		/**
		 * Resumes a session
		 */
		resumeSession() {
			const token = this.$session.get('token');
			const user = this.$session.get('user');
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
			store.commit('setLoggedUser', user);
		},
		/**
		 * Ends session and clears session data
		 */
		endSession() {
			this.$session.clear();
			this.$session.destroy();
			clearSession();
			delete axios.defaults.headers.common.Authorization;
			store.commit('logout');
			setTimeout(() => {
				this.$router.push('/login');
			}, 500);
		}
	}
}).$mount('#app');
