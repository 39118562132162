/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	settings: [],
	settingDetail: {},
	settingsUpdate: null,
	totalSettings: 0
};

const getters = {
	getSettings: (state) => state.settings,
	getSettingDetail: (state) => state.settingDetail,
	getSettingsUpdate: (state) => state.settingsUpdate,
	getTotalSettings: (state) => state.totalSettings
};

const mutations = {
	setSettings: (state, data) => {
		state.settings = data;
	},

	addSetting: (state, data) => {
		state.settings = [data, ...state.settings];
	},

	setSettingDetail: (state, data) => {
		state.settingDetail = data;
	},

	updateSettingDetail: (state, data) => {
		state.settingDetail = { ...state.settingDetail, ...data };
	},

	updateSuccess: (state) => {
		state.settingsUpdate = 'success';
	},

	updateError: (state) => {
		state.settingsUpdate = 'error';
	},

	resetUpdate: (state) => {
		state.settingsUpdate = null;
	},

	setTotal: (state, data) => {
		state.totalSettings = data;
	}
};

const actions = {
	fetchAllSettings: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`settings?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setSettings', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'fetch-settings', ...(error.response.data || 'Error Fetching bVanks, Please Try Again.') },
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	createSetting: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('settings', data)
			.then((response) => {
				if (response.status === 201) {
					commit('addSetting', response.data);
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'A new Setting has been created'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'register', ...(error.response.data || 'An Error occur, Please Try Again.') },
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchSettingDetail: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`settings/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setSettingDetail', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'setting-detail' }, { root: true });
				return error;
			});
		return true;
	},

	updateSetting: ({ commit }, payload) => {
		const { data, id } = payload;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`settings/${id}`, { ...data })
			.then((response) => {
				if (response.status === 200) {
					commit('updateSettingDetail', response.data);
					commit('updateSuccess');
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'Setting details have been updated!'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'updateSetting' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			})
			.finally(() => {
				commit('resetUpdate');
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
