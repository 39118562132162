/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Router from 'vue-router';
import { requireAuth, noAuthOnly } from '../utils/auth';

const Base = () => import(/* webpackChunkName: 'base' */ '../pages/Base');
const Login = () => import(/* webpackChunkName: 'login' */ '../pages/Login');
const Logout = () => import(/* webpackChunkName: 'login' */ '../pages/Logout');
const Overview = () => import(/* webpackChunkName: 'overview' */ '../pages/Overview');
const Investors = () => import(/* webpackChunkName: 'investors' */ '../pages/investors/Index.vue');
const InvestorDetails = () => import(/* webpackChunkName: 'investor-profile' */ '../pages/investors/InvestorProfile');
const ForgotPassword = () => import(/* webpackChunkName: 'forgot-password' */ '../pages/ForgotPassword');
const MutualFunds = () => import(/* webpackChunkName: 'mutual-funds' */ '../pages/mutualFunds/Index');
const MutualFundDetails = () => {
	return import(/* webpackChunkName: 'mutualFund-details' */ '../pages/mutualFunds/MutualFundDetails');
};
const Countries = () => import(/* webpackChunkName: 'countries' */ '../pages/countries/Index.vue');
const CountryDetails = () => import(/* webpackChunkName: 'country-details' */ '../pages/countries/CountryDetails');
const GiftCards = () => import(/* webpackChunkName: 'giftCards' */ '../pages/giftCards/Index');
const GiftCardDetails = () => {
	return import(/* webpackChunkName: 'giftCard-details' */ '../pages/giftCards/GiftCardDetails.vue');
};
const Clients = () => import(/* webpackChunkName: 'clients' */ '../pages/clients/Index.vue');
const Banks = () => import(/* webpackChunkName: 'banks' */ '../pages/banks/Index.vue');
const BankDetails = () => import(/* webpackChunkName: 'bank-details' */ '../pages/banks/bankDetail.vue');
const Stocks = () => import(/* webpackChunkName: 'banks' */ '../pages/stocks/Index.vue');
const StockDetails = () => import(/* webpackChunkName: 'stock-details' */ '../pages/stocks/StockDetail.vue');
const Settings = () => import(/* webpackChunkName: 'settings' */ '../pages/settings/Index.vue');
const SettingDetails = () => import(/* webpackChunkName: 'setting-details' */ '../pages/settings/SettingDetail.vue');
const Audits = () => import(/* webpackChunkName: 'audits' */ '../pages/audits/Index.vue');
const AuditDetails = () => import(/* webpackChunkName: 'audit-details' */ '../pages/audits/AuditDetail.vue');
const WithdrawalRequests = () => {
	return import(/* webpackChunkName: 'withdrawalRequests' */ '../pages/withdrawalRequests/Index.vue');
};
const Orders = () => import(/* webpackChunkName: 'orders' */ '../pages/orders/Index.vue');
const OrderDetails = () => import(/* webpackChunkName: 'order-details' */ '../pages/orders/OrderDetail.vue');
const Referrals = () => import(/* webpackChunkName: 'referrals' */ '../pages/referrals/Index.vue');
const Commissions = () => import(/* webpackChunkName: 'referrals' */ '../pages/commissions/Index.vue');
const Savings = () => import(/* webpackChunkName: 'orders' */ '../pages/savings/Index.vue');
const SavingDetails = () => import(/* webpackChunkName: 'order-details' */ '../pages/savings/SavingDetail.vue');
const InvestorMutualFunds = () =>
	import(/* webpackChunkName: 'order-details' */ '../pages/InvestorMutualFunds/Index.vue');
const InvestorMutualFundDetail = () =>
	import(/* webpackChunkName: 'order-details' */ '../pages/InvestorMutualFunds/InvestorMutualFundDetail.vue');
const Faqs = () =>
	import(/* webpackChunkName: 'faqs' */ '../pages/faqs/Index.vue');
const FaqDetail = () =>
	import(/* webpackChunkName: 'faq-details' */ '../pages/faqs/FaqDetails.vue');

Vue.use(Router);

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			component: Base,
			children: [
				{
					path: '',
					name: 'overview',
					component: Overview,
					beforeEnter: requireAuth
				},
				{
					path: 'investors',
					name: 'investors',
					component: Investors,
					beforeEnter: requireAuth
				},
				{
					path: 'investors/:id',
					name: 'investor-profile',
					component: InvestorDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'mutual-funds',
					name: 'mutual-funds',
					component: MutualFunds,
					beforeEnter: requireAuth
				},
				{
					path: 'mutual-funds/:id',
					name: 'mutualFund-details',
					component: MutualFundDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'countries',
					name: 'countries',
					component: Countries,
					beforeEnter: requireAuth
				},
				{
					path: 'countries/:id',
					name: 'country-details',
					component: CountryDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'gift-cards',
					name: 'giftCards',
					component: GiftCards,
					beforeEnter: requireAuth
				},
				{
					path: 'gift-cards/:id',
					name: 'giftCards-details',
					component: GiftCardDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'clients',
					name: 'clients',
					component: Clients,
					beforeEnter: requireAuth
				},
				{
					path: 'banks',
					name: 'banks',
					component: Banks,
					beforeEnter: requireAuth
				},
				{
					path: 'banks/:id',
					name: 'bank-detail',
					component: BankDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'stocks',
					name: 'stocks',
					component: Stocks,
					beforeEnter: requireAuth
				},
				{
					path: 'stocks/:id',
					name: 'stock-detail',
					component: StockDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'settings',
					name: 'settings',
					component: Settings,
					beforeEnter: requireAuth
				},
				{
					path: 'settings/:id',
					name: 'setting-detail',
					component: SettingDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'audits',
					name: 'audits',
					component: Audits,
					beforeEnter: requireAuth
				},
				{
					path: 'audits/:id',
					name: 'audit-detail',
					component: AuditDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'withdrawals',
					name: 'withdrawalRequests',
					component: WithdrawalRequests,
					beforeEnter: requireAuth
				},
				{
					path: 'orders',
					name: 'orders',
					component: Orders,
					beforeEnter: requireAuth
				},
				{
					path: 'orders/:id',
					name: 'order-detail',
					component: OrderDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'referrals',
					name: 'referrals',
					component: Referrals,
					beforeEnter: requireAuth
				},
				{
					path: 'commissions',
					name: 'commissions',
					component: Commissions,
					beforeEnter: requireAuth
				},
				{
					path: 'savings',
					name: 'savings',
					component: Savings,
					beforeEnter: requireAuth
				},
				{
					path: 'savings/:id',
					name: 'saving-detail',
					component: SavingDetails,
					beforeEnter: requireAuth
				},
				{
					path: 'investor-mf',
					name: 'investor-mf',
					component: InvestorMutualFunds,
					beforeEnter: requireAuth
				},
				{
					path: 'investor-mf/:id',
					name: 'investorMutualFunds-detail',
					component: InvestorMutualFundDetail,
					beforeEnter: requireAuth
				},
				{
					path: 'faqs',
					name: 'faqs',
					component: Faqs,
					beforeEnter: requireAuth
				},
				{
					path: 'faqs/:id',
					name: 'faq-details',
					component: FaqDetail,
					beforeEnter: requireAuth
				}
			]
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			beforeEnter: noAuthOnly
		},
		{
			path: '/logout',
			name: 'logout',
			component: Logout,
			beforeEnter: requireAuth
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: ForgotPassword,
			beforeEnter: noAuthOnly
		}
	]
});
