/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	banks: [],
	bankProfile: {},
	bankUpdate: null,
	totalbanks: 0
};

const getters = {
	getBanks: (state) => state.banks,
	getBankProfile: (state) => state.bankProfile,
	getBankUpdate: (state) => state.bankUpdate,
	getTotalBanks: (state) => state.totalBanks
};

const mutations = {
	setBanks: (state, data) => {
		state.banks = data;
	},

	addBank: (state, data) => {
		state.banks = [data, ...state.banks];
	},

	setBankProfile: (state, data) => {
		state.bankProfile = data;
	},

	updateBankProfile: (state, data) => {
		state.bankProfile = { ...state.bankProfile, ...data };
	},

	updateSuccess: (state) => {
		state.bankUpdate = 'success';
	},

	updateError: (state) => {
		state.bankUpdate = 'error';
	},

	resetUpdate: (state) => {
		state.bankUpdate = null;
	},

	setTotal: (state, data) => {
		state.totalBanks = data;
	}
};

const actions = {
	fetchAllBanks: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`banks?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setBanks', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'fetch-banks', ...(error.response.data || 'Error Fetching bVanks, Please Try Again.') },
					{ root: true }
				);
				return error;
			});
		return true;
	},

	createBank: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('banks', data)
			.then((response) => {
				if (response.status === 201) {
					commit('addBank', response.data);
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'A new Bank has been created'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'register', ...(error.response.data || 'An Error occur, Please Try Again.') },
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return error;
			});
		return true;
	},

	fetchBankProfile: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`banks/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setBankProfile', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'bank-profile' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	updateBank: ({ commit }, payload) => {
		const { data, id } = payload;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`banks/${id}`, { ...data })
			.then((response) => {
				if (response.status === 200) {
					commit('updateBankProfile', response.data);
					commit('updateSuccess');
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'Bank details have been updated!'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'updateBank' }, { root: true });
				return error;
			})
			.finally(() => {
				commit('resetUpdate');
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
