/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import api from '../../utils/api';

const state = {
	investorMutualFunds: [],
	investorMutualFundsDetail: {},
	investorMutualFundsTotal: ''
};

const getters = {
	getInvestorMutualFunds: (state) => state.investorMutualFunds,
	getInvestorMutualFundsDetail: (state) => state.investorMutualFundsDetail,
	getInvestorMutualFundsTotal: (state) => state.investorMutualFundsTotal
};

const mutations = {
	setInvestorMutualFunds: (state, data) => {
		state.investorMutualFunds = data;
	},
	setInvestorMutualFundsDetail: (state, data) => {
		state.investorMutualFundsDetail = data;
	},
	setTotal: (state, data) => {
		state.investorMutualFundsTotal = data;
	}
};

const actions = {
	fetchAllInvestorMutualFunds: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`mutualfunds/portfolios?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorMutualFunds', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'investor-mutual-funds',
						...(error.response.data || 'Error Fetching investor MutualFunds, Please Try Again.')
					},
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchInvestorMutualFundsDetail: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${data.userId}/mutualfunds/${data.mutualFundId}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorMutualFundsDetail', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'investor-mutual-fund-detail' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
