<template>
	<div id="app">
		<router-view :key="key" />
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import './assets/scss/main.scss';

export default {
	name: 'App',
	data() {
		return {
			key: 0,
			events: ['click', 'mouseover', 'mousedown', 'scroll', 'keypress', 'load', 'mousemove'],
			logoutTimer: null
		};
	},
	watch: {
		refreshingToken(val) {
			if (val) {
				this.key += 1;
			}
		}
	},
	computed: {
		...mapGetters(['refreshingToken', 'getLoggedUser'])
	},
	mounted() {
		if (this.getLoggedUser) {
			this.events.forEach((event) => {
				window.addEventListener(event, this.resetTimer);
			}, this);
			this.setTimers();
		}
	},
	methods: {
		...mapMutations(['logout', 'resetAll']),
		autoLogout() {
			this.logout();
			this.resetAll();
			this.$router.push('/login');
		},
		setTimers() {
			this.logoutTimer = setTimeout(this.autoLogout, 5 * 60 * 1000);
		},
		resetTimer() {
			clearTimeout(this.logoutTimer);
			this.setTimers();
		}
	},
	destroyed() {
		this.events.forEach((event) => {
			window.removeEventListener(event, this.resetTimer);
		}, this);
		this.resetTimer();
	}
};
</script>

<style lang="scss">
@import './assets/scss/main.scss';

#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
</style>
