/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	stocks: [],
	stockDetail: {},
	totalStocks: 0
};

const getters = {
	getStocks: (state) => state.stocks,
	getStockDetail: (state) => state.stockDetail,
	getTotalStocks: (state) => state.totalStocks
};

const mutations = {
	setStocks: (state, data) => {
		state.stocks = data;
	},

	updateSuccess: (state) => {
		state.updateStatus = 'success';
	},

	updateError: (state) => {
		state.updateStatus = 'error';
	},

	resetUpdate: (state) => {
		state.updateStatus = null;
	},

	setTotal: (state, data) => {
		state.totalStocks = data;
	},

	setStockDetail: (state, data) => {
		state.stockDetail = data;
	}
};

const actions = {
	fetchAllStocks: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`instruments?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setStocks', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'fetch-stocks', ...(error.response.data || 'Error Fetching bVanks, Please Try Again.') },
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchStockDetail: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`instruments/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setStockDetail', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'stock-detail' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
