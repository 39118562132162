/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	countries: [],
	countryDetails: {},
	searchCountries: [],
	totalCountries: 0
};

const getters = {
	getCountries: (state) => state.countries,
	getCountryDetails: (state) => state.countryDetails,
	getCountriesSearch: (state) => state.searchCountries,
	getTotalCountries: (state) => state.totalCountries,
	getTotalSearchCountries: (state) => state.searchCountriesTotal
};

const mutations = {
	setCountries: (state, data) => {
		state.countries = data;
	},

	addCountry: (state, data) => {
		state.countries = [data, ...state.countries];
	},

	updateCountries: (state, data) => {
		state.countries.data = { data, ...state.countries.data };
	},

	setCountryDetails: (state, data) => {
		state.countryDetails = data;
	},
	updateCountryDetails: (state, data) => {
		state.countryDetails = { ...state.countryDetails, data };
	},

	setCountriesSearch: (state, data) => {
		state.searchCountries = data;
	},

	setSearchCountriesTotal: (state, data) => {
		state.searchCountriesTotal = data;
	},

	setTotal: (state, data) => {
		state.totalCountries = data;
	}
};

const actions = {
	fetchAllCountries: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`countries?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setCountries', response.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'countries', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchCountryDetails: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`countries/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setCountryDetails', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'countries', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	createCountry: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('countries', data)
			.then((response) => {
				if (response.status === 201) {
					success({
						title: 'Success',
						text: 'A new country has been created'
					});
					commit('reqSuccess', null, { root: true });
					commit('updateCountries', response);
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'createCountry', ...(error.response.data || 'Something went wrong, Please Try Again.') },
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	updateCountries: ({ commit }, { id, data }) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`countries/${id}`, data)
			.then((response) => {
				if (response.status === 200) {
					commit('reqSuccess', null, { root: true });
					commit('updateCountryDetails', response.data);
					success({
						title: 'Success',
						text: 'Country has been updated'
					});
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'countries', ...(error.response.data || 'Error updating countries, Please Try Again.') },
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	searchCountries: ({ commit }, data) => {
		const { limit, offset, query } = data;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`countries/search?query=${query}&type=user&limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setCountriesSearch', response.data.data);
					commit('setSearchCountriesTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'search-countries', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
