/* eslint-disable import/no-cycle */
import instance from './axios';

export default {
	get(url) {
		return instance.get(url);
	},
	post(url, data) {
		return instance.post(url, data);
	},
	patch(url, data) {
		return instance.patch(url, data);
	},
	delete(url) {
		return instance.delete(url);
	}
};
