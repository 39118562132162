/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	audits: [],
	auditDetail: {},
	totalAudits: 0,
	searchAudits: []
};

const getters = {
	getAudits: (state) => state.audits,
	getAuditDetail: (state) => state.auditDetail,
	getTotalAudits: (state) => state.totalAudits,
	getAuditsSearch: (state) => state.searchAudits
};

const mutations = {
	setAudits: (state, data) => {
		state.audits = data;
	},

	setAuditDetail: (state, data) => {
		state.auditDetail = data;
	},

	updateSuccess: (state) => {
		state.updateStatus = 'success';
	},

	updateError: (state) => {
		state.updateStatus = 'error';
	},

	resetUpdate: (state) => {
		state.updateStatus = null;
	},

	setTotal: (state, data) => {
		state.totalAudits = data;
	},
	setSearchAuditsTotal: (state, data) => {
		state.searchAuditsTotal = data;
	},
	setAuditsSearch: (state, data) => {
		state.searchAudits = data;
	}
};

const actions = {
	fetchAllAudits: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`audits?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setAudits', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'fetch-audits', ...(error.response.data || 'Error Fetching audits, Please Try Again.') },
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},
	fetchAuditDetail: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`audits/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setAuditDetail', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'audit-detail' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},
	searchAudits: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`audits/search?query=${data}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setAuditsSearch', response.data.data);
					commit('setSearchAuditsTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'search-audits', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
