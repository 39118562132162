/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	mutualFunds: [],
	mutualFundDetails: {},
	searchmutualFunds: [],
	totalmutualFunds: 0
};

const getters = {
	getMutualFunds: (state) => state.mutualFunds,
	getMutualFundDetails: (state) => state.mutualFundDetails,
	getMutualFundsSearch: (state) => state.searchMutualFunds,
	getTotalMutualFunds: (state) => state.totalMutualFunds,
	getTotalSearchMutualFunds: (state) => state.searchMutualFundsTotal
};

const mutations = {
	setMutualFunds: (state, data) => {
		state.mutualFunds = data;
	},

	addMutualFund: (state, data) => {
		state.mutualFunds = [data, ...state.mutualFunds];
	},

	updateMutualFunds: (state, data) => {
		state.mutualFunds.data = { data, ...state.mutualFunds.data };
	},

	setMutualFundDetails: (state, data) => {
		state.mutualFundDetails = data;
	},
	updateMutualFundDetails: (state, data) => {
		state.mutualFundDetails = { ...state.mutualFundDetails, data };
	},

	setMutualFundsSearch: (state, data) => {
		state.searchMutualFunds = data;
	},

	setSearchMutualFundsTotal: (state, data) => {
		state.searchMutualFundsTotal = data;
	},

	setTotal: (state, data) => {
		state.totalMutualFunds = data;
	},

	deleteMutualFund: (state, id) => {
		state.mutualFunds.data = state.mutualFunds.data.filter((mutualFund) => mutualFund.id !== id);
	}
};

const actions = {
	fetchAllMutualFunds: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`mutualfunds?status=all&limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setMutualFunds', response.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'mutualFunds', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchMutualFundDetails: ({ commit }, data) => {
		const { id, status } = data;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`mutualfunds/${id}?active=${status}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setMutualFundDetails', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'mutualFunds', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	createMutualFund: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('mutualfunds', data)
			.then((response) => {
				if (response.status === 201) {
					success({
						title: 'Success',
						text: 'A new Mutual Fund has been created'
					});
					commit('reqSuccess', null, { root: true });
					commit('updateMutualFunds', response);
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'createMutualFund', ...(error.response.data || 'Something went wrong, Please Try Again.') },
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	updateMutualFunds: ({ commit }, payload) => {
		const { data, id } = payload;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`mutualfunds/${id}`, { ...data })
			.then((response) => {
				if (response.status === 200) {
					commit('reqSuccess', null, { root: true });
					commit('updateMutualFundDetails', response.data);
					success({
						title: 'Success',
						text: 'Mutual Fund has been updated'
					});
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'mutualFunds',
						...(error.response.data || 'Error updating Mutual Funds, Please Try Again.')
					},
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	deleteMutualFunds: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.delete(`mutualfunds/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('reqSuccess', null, { root: true });
					commit('deleteMutualFund', id);
					success({
						title: 'Success',
						text: 'Mutual Fund has been updated'
					});
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'mutualFund-delete',
						...(error.response.data || 'An Error occur, Please Try Again.')
					},
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
