/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { restartSession } from '../../utils/auth';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	isLoggedIn: false,
	loggedUser: {},
	loginSuccess: null,
	refreshingToken: false
};

const getters = {
	getLoggedUser: (state) => state.loggedUser,
	isLoggedIn: (state) => state.isLoggedIn,
	refreshingToken: (state) => state.refreshingToken
};

const mutations = {
	logout: (state) => {
		state.loggedUser = {};
		state.isLoggedIn = false;
	},
	setLoggedUser: (state, user) => {
		state.loggedUser = user;
	},
	loginSuccess: (state, data) => {
		state.loggedUser = data;
		state.isLoggedIn = true;
	},
	updateLoggedUser: (state, data) => {
		state.loggedUser = { ...state.loggedUser, ...data };
	},
	updateTokenStatus: (state, data) => {
		state.refreshingToken = data;
	}
};

const actions = {
	login: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('login', data)
			.then((response) => {
				if (response.data.data.admin !== 1) {
					commit('reqError', null, { root: true });
				} else if (response.status === 200 && 'token' in response.data && response.data.data.admin === 1) {
					const user = response.data;
					user.token = response.data.token;
					user.refreshToken = response.data.refreshToken;
					commit('loginSuccess', user);
					commit('reqSuccess', null, { root: true });
					return true;
				} else {
					commit('reqError', null, { root: true });
					return false;
				}
				return false;
			})
			.catch((error) => {
				if (error.response === undefined) {
					commit('reqError', null, { root: true });
					commit(
						'logError',
						{ type: 'login', ...(error.message || 'Authentication Error, Please Try Again.') },
						{ root: true }
					);
					bugsnagClient.notify(error);
					return error;
				}
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'login', ...(error.response.data || 'Authentication Error, Please Try Again.') },
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	doTokenRefresh: ({ commit, state }) => {
		const data = {
			refreshToken: state.loggedUser.refreshToken,
			email: state.loggedUser.data.email
		};
		api.post('token', data)
			.then((response) => {
				if (response.status === 200) {
					const user = response.data.data;
					user.token = response.data.token;
					user.refreshToken = response.data.refreshToken;
					commit('updateLoggedUser', user);
					commit('updateTokenStatus', true);
					restartSession(user);
				}
			})
			.catch((error) => {
				bugsnagClient.notify(error);
				return error;
			})
			.finally(() => {
				commit('updateTokenStatus', false);
			});
		return true;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
