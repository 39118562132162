/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	commissions: [],
	totalCommissions: '',
	commissionDetail: {}
};

const getters = {
	getCommissions: (state) => state.commissions,
	getTotalCommissions: (state) => state.totalCommissions,
	getCommissionDetail: (state) => state.commissionDetail
};

const mutations = {
	setCommissions: (state, data) => {
		state.commissions = data;
	},

	setTotal: (state, data) => {
		state.totalCommissions = data;
	},

	setCommissionDetail: (state, data) => {
		state.commissionDetail = data;
	},

	setUpdateCommission: (state, data) => {
		state.commissionDetail = { ...state.commissionDetail, ...data };
	}
};

const actions = {
	fetchAllCommissions: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`/commissions?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setCommissions', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'fetch-commissions',
						...(error.response.data || 'Error Fetching Commissions, Please Try Again.')
					},
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchSingleCommission: ({ commit }) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`commissions/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setCommissionDetail', response.data.data);
					commit('updateSuccess');
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'commission-detail' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	updateCommission: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		const { id, is_reconciled: isReconciled } = { ...data };
		api.patch(`/commissions/${id}`, { is_reconciled: isReconciled })
			.then((response) => {
				if (response.status === 200) {
					commit('setUpdateCommission', response.data);
					commit('updateSuccess');
					commit('reqSuccess', null, { root: true });
					success({
						title: 'Success',
						text: 'Commission status has been updated!'
					});
					return true;
				}
				commit('reqError', null, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'updateCommission' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			})
			.finally(() => {
				commit('resetUpdate');
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
