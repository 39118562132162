/* eslint-disable import/no-cycle */
import axios from 'axios';
import store from '../store';
import router from '../router';

const instance = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}`,
	headers: {
		'Client-ID': `${process.env.VUE_APP_CLIENT_ID}`,
		'Client-Key': `${process.env.VUE_APP_CLIENT_KEY}`,
		'Client-Secret': `${process.env.VUE_APP_CLIENT_SECRET}`
	},
	crossdomain: true
});
instance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 403 || error.response.status === 401) {
			if (error.config.url === `${process.env.VUE_APP_API_URL}/token`) {
				router.push('/login');
				return Promise.reject(error);
			}
			return store.dispatch('doTokenRefresh');
		}
		return Promise.reject(error);
	}
);

export default instance;
