/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import api from '../../utils/api';

const state = {
	savings: [],
	savingsDetail: {},
	savingsTotal: ''
};

const getters = {
	getSavings: (state) => state.savings,
	getSavingsDetail: (state) => state.savingsDetail,
	getSavingsTotal: (state) => state.savingsTotal
};

const mutations = {
	setSavings: (state, data) => {
		state.savings = data;
	},
	setSavingsDetail: (state, data) => {
		state.savingsDetail = data;
	},
	setTotal: (state, data) => {
		state.savingsTotal = data;
	}
};

const actions = {
	fetchAllSavings: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`savings?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setSavings', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'savings', ...(error.response.data || 'Error Fetching savings, Please Try Again.') },
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchSavingsDetail: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`savings/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setSavingsDetail', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'savings-detail' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
