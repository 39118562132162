/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const actions = {
	sendEmail: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('messages/email', data)
			.then((response) => {
				if (response.status === 200) {
					success({
						title: 'Success! Email has been sent!'
					});
					commit('reqSuccess', null, { root: true });
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'message-email', ...error.response.data }, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	sendSingleEmail: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('messages/contact', data)
			.then((response) => {
				if (response.status === 200) {
					success({
						title: 'Success! Email has been sent!'
					});
					commit('reqSuccess', null, { root: true });
					return true;
				}
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'messages', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	sendNotification: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('messages/push', data)
			.then((response) => {
				if (response.status === 200) {
					success({
						title: 'Success! Notification has been sent!'
					});
					commit('reqSuccess', null, { root: true });
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'message-notification', ...error.response.data }, { root: true });
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	actions
};
