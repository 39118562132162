/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import auth from './modules/auth';
import investors from './modules/investors';
import countries from './modules/countries';
import messages from './modules/messages';
import mutualFunds from './modules/mutualFunds';
import giftCards from './modules/giftCards';
import clients from './modules/clients';
import banks from './modules/banks';
import stocks from './modules/stocks';
import settings from './modules/settings';
import audits from './modules/audits';
import withdrawalRequests from './modules/withdrawalRequests';
import orders from './modules/orders';
import referrals from './modules/referrals';
import commissions from './modules/commissions';
import savings from './modules/savings';
import investorMutualFunds from './modules/investorMutualFunds';
import faqs from './modules/faqs';
import transactions from './modules/transactions';

const vuexPersistence = new VuexPersistence({
	storage: localStorage
});

Vue.use(Vuex);

const initialState = {
	auth: auth.state,
	investors: investors.state,
	countries: countries.state,
	messages: messages.state,
	mutualFunds: mutualFunds.state,
	giftCards: giftCards.state,
	clients: clients.state,
	banks: banks.state,
	stocks: stocks.state,
	settings: settings.state,
	audits: audits.state,
	withdrawalRequests: withdrawalRequests.state,
	orders: orders.state,
	referrals: referrals.state,
	savings: savings.state,
	faqs: faqs.state,
	transactions: transactions.state
};

const store = new Vuex.Store({
	state: {
		status: '',
		errorLog: {}
	},
	mutations: {
		reqInit: (state) => {
			state.status = 'loading';
		},
		reqSuccess: (state) => {
			state.status = 'success';
		},
		reqError: (state) => {
			state.status = 'error';
		},
		logError: (state, data) => {
			state.errorLog = data;
		},
		resetReq: (state) => {
			state.status = '';
			state.errorLog = {};
		},
		resetAll: (state) => {
			Object.keys(state).forEach((key) => {
				Object.assign(state[key], initialState[key]);
			});
		},
		resetSidebar: (state) => {
			state.isSidebarOpen = false;
		},
		restoreSession: (state, data) => {
			auth.state.isLoggedIn = data;
		}
	},
	getters: {
		getStatus: (state) => state.status,
		getErrorLog: (state) => state.errorLog
	},
	modules: {
		auth,
		investors,
		countries,
		messages,
		mutualFunds,
		giftCards,
		clients,
		banks,
		stocks,
		settings,
		audits,
		withdrawalRequests,
		orders,
		referrals,
		commissions,
		savings,
		investorMutualFunds,
		faqs,
		transactions
	},
	plugins: [vuexPersistence.plugin]
});

export default store;
