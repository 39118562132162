/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	giftCards: [],
	giftCardDetails: {},
	searchGiftCards: [],
	totalGiftCards: 0
};

const getters = {
	getGiftCards: (state) => state.giftCards,
	getGiftCardDetails: (state) => state.giftCardDetails,
	getGiftCardsSearch: (state) => state.searchGiftCards,
	getTotalGiftCards: (state) => state.totalGiftCards,
	getTotalSearchGiftCards: (state) => state.searchGiftCardsTotal
};

const mutations = {
	setGiftCards: (state, data) => {
		state.giftCards = data;
	},

	addGiftCard: (state, data) => {
		state.giftCards = [data, ...state.giftCards];
	},

	updateGiftCards: (state, data) => {
		state.giftCards.data = { data, ...state.giftCards.data };
	},

	setGiftCardDetails: (state, data) => {
		state.giftCardDetails = data;
	},
	updateGiftCardDetails: (state, data) => {
		state.giftCardDetails = { ...state.giftCardDetails, data };
	},

	setGiftCardsSearch: (state, data) => {
		state.searchGiftCards = data;
	},

	setSearchGiftCardsTotal: (state, data) => {
		state.searchGiftCardsTotal = data;
	},

	setTotal: (state, data) => {
		state.totalGiftCards = data;
	}
};

const actions = {
	fetchAllGiftCards: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`giftcards?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setGiftCards', response.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'giftCards', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchGiftCardDetails: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`giftcards/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setGiftCardDetails', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'giftCards', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},
	updateGiftCards: ({ commit }, { id, data }) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`giftcards/${id}`, data)
			.then((response) => {
				if (response.status === 200) {
					commit('reqSuccess', null, { root: true });
					commit('updateGiftCardDetails', response.data);
					success({
						title: 'Success',
						text: 'Gift Card has been updated'
					});
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'giftCards',
						...(error.response.data || 'Error updating Gift Cards, Please Try Again.')
					},
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	searchGiftCards: ({ commit }, data) => {
		const { query } = data;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`giftcards/search?code=${query}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setGiftCardsSearch', response.data.data);
					commit('setSearchGiftCardsTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('setGiftCardsSearch', []);
				commit('reqError', null, { root: true });
				commit('logError', { type: 'search-giftCards', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
