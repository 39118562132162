export function success(obj = {}) {
	swal.fire({
		type: 'success',
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		title: '',
		...obj
	});
}

export function fail(obj = {}) {
	swal.fire({
		toast: true,
		position: 'top-end',
		timer: 3000,
		title: 'Oops!',
		type: 'error',
		showConfirmButton: false,
		...obj
	});
}
