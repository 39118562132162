/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	transactions: [],
	totalTransactions: 0
};

const getters = {
	getInvestorTransactions: (state) => state.transactions,
	getTotalInvestorTransactions: (state) => state.totalTransactions
};

const mutations = {
	setInvestorTransactions: (state, data) => {
		state.transactions = data;
	},

	setInvestorTotal: (state, data) => {
		state.totalTransactions = data;
	}
};

const actions = {
	fetchInvestorTransactions: ({ commit }, data) => {
		const [limit, offset, id] = [...data];
		const query = 'Payment, GiftCard, MutualFundPortfolio, Order';
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`users/${id}/audits?query=${query}&limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setInvestorTransactions', response.data.data);
					commit('setInvestorTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ 
						type: 'fetch-transactions',
						...(error.response.data || 'Error Fetching Investor Transactions, Please Try Again.')
					},
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
