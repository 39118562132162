/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	clients: [],
	clientProfile: {},
	clientUpdate: null,
	totalClients: 0,
	card: {},
	searchClients: [],
	searchClientsTotal: 0
};

const getters = {
	getClients: (state) => state.clients,
	getClientProfile: (state) => state.clientProfile,
	getClientUpdate: (state) => state.clientUpdate,
	getClientCard: (state) => state.card,
	getTotalClients: (state) => state.totalClients,
	getClientsSearch: (state) => state.searchClients,
	getTotalSearchClients: (state) => state.searchClientsTotal
};

const mutations = {
	setClients: (state, data) => {
		state.clients = data;
	},

	addClient: (state, data) => {
		state.clients = [data, ...state.clients];
	},

	setClientProfile: (state, data) => {
		state.clientProfile = data;
	},

	updateClientProfile: (state, data) => {
		state.clientProfile = { ...state.clientProfile, ...data };
	},

	updateSuccess: (state) => {
		state.clientUpdate = 'success';
	},

	updateError: (state) => {
		state.clientUpdate = 'error';
	},

	resetUpdate: (state) => {
		state.clientUpdate = null;
	},

	setCard: (state, data) => {
		state.card = data;
	},

	setTotal: (state, data) => {
		state.totalClients = data;
	},

	setClientsSearch: (state, data) => {
		state.searchClients = data;
	},

	setSearchClientsTotal: (state, data) => {
		state.searchClientsTotal = data;
	}
};

const actions = {
	fetchAllClients: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`clients?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setClients', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'fetch-clients', ...(error.response.data || 'Error Fetching Clients, Please Try Again.') },
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
