/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	orders: [],
	orderDetail: {},
	totalOrders: '',
	searchOrders: [],
	searchOrdersTotal: ''
};

const getters = {
	getOrders: (state) => state.orders,
	getOrderDetail: (state) => state.orderDetail,
	getTotalOrders: (state) => state.totalOrders,
	getOrdersSearch: (state) => state.searchOrders,
	getTotalSearchOrders: (state) => state.searchOrdersTotal
};

const mutations = {
	setOrders: (state, data) => {
		state.orders = data;
	},

	setOrderDetail: (state, data) => {
		state.orderDetail = data;
	},

	setTotal: (state, data) => {
		state.totalOrders = data;
	},

	setOrdersSearch: (state, data) => {
		state.searchOrders = data;
	},

	setSearchOrdersTotal: (state, data) => {
		state.searchOrdersTotal = data;
	}
};

const actions = {
	fetchAllOrders: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`orders?limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setOrders', response.data.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'fetch-orders', ...(error.response.data || 'Error Fetching Orders, Please Try Again.') },
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchOrderDetail: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`orders/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setOrderDetail', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { ...error.response.data, type: 'order-detail' }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	searchOrders: ({ commit }, data) => {
		const { limit, offset, query } = data;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`orders/search?query=${query}&limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setOrdersSearch', response.data.data);
					commit('setSearchOrdersTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'search-orders', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
