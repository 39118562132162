/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }]	*/
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import api from '../../utils/api';
import { success, fail } from '../../utils/toast';

const options = {
	persist: true
};

Vue.use(Vuex);
Vue.use(VueSession, options);

const state = {
	faqs: [],
	faqDetails: {},
	totalfaqs: 0
};

const getters = {
	getFaqs: (state) => state.faqs,
	getFaqDetails: (state) => state.faqDetails,
	getTotalFaqs: (state) => state.totalFaqs
};

const mutations = {
	setFaqs: (state, data) => {
		state.faqs = data;
	},

	addFaq: (state, data) => {
		state.faqs = [data, ...state.faqs];
	},

	updateFaqs: (state, data) => {
		state.faqs.data = { data, ...state.faqs.data };
	},

	setFaqDetails: (state, data) => {
		state.faqDetails = data;
	},
	updateFaqDetails: (state, data) => {
		state.faqDetails = { ...state.faqDetails, data };
	},

	setTotal: (state, data) => {
		state.totalFaqs = data;
	},

	deleteFaq: (state, id) => {
		state.faqs.data = state.faqs.data.filter((faq) => faq.id !== id);
	}
};

const actions = {
	fetchAllFaqs: ({ commit }, data) => {
		const [limit, offset] = [...data];
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`faqs?status=all&limit=${limit}&offset=${offset}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setFaqs', response.data);
					commit('setTotal', response.data.total);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'faqs', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	fetchFaqDetails: ({ commit }, data) => {
		const { id, status } = data;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.get(`faqs/${id}?active=${status}`)
			.then((response) => {
				if (response.status === 200) {
					commit('setFaqDetails', response.data.data);
					commit('reqSuccess', null, { root: true });
					return true;
				}
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit('logError', { type: 'faqs', ...error.response.data }, { root: true });
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	createFaq: ({ commit }, data) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.post('faqs', data)
			.then((response) => {
				if (response.status === 201) {
					success({
						title: 'Success',
						text: 'A new Faq has been created'
					});
					commit('reqSuccess', null, { root: true });
					commit('updateFaqs', response);
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{ type: 'createFaq', ...(error.response.data || 'Something went wrong, Please Try Again.') },
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	updateFaqs: ({ commit }, payload) => {
		const { data, id } = payload;
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.patch(`faqs/${id}`, { ...data })
			.then((response) => {
				if (response.status === 200) {
					commit('reqSuccess', null, { root: true });
					commit('updateFaqDetails', response.data);
					success({
						title: 'Success',
						text: 'Faq has been updated'
					});
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'faqs',
						...(error.response.data || 'Error updating Faqs, Please Try Again.')
					},
					{ root: true }
				);
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	},

	deleteFaqs: ({ commit }, id) => {
		commit('resetReq', null, { root: true });
		commit('reqInit', null, { root: true });
		api.delete(`faqs/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit('reqSuccess', null, { root: true });
					commit('deleteFaq', id);
					success({
						title: 'Success',
						text: 'Faq has been deleted'
					});
					return true;
				}
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				return false;
			})
			.catch((error) => {
				fail({
					title: 'Error',
					text: 'Oops! Something went wrong'
				});
				commit('reqError', null, { root: true });
				commit(
					'logError',
					{
						type: 'faq-delete',
						...(error.response.data || 'An Error occur, Please Try Again.')
					},
					{ root: true }
				);
				bugsnagClient.notify(error);
				return error;
			});
		return true;
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
